html {
  font-size: 62.5%;
}

@include mq('PC') {
  html, body {
    min-width: 1006px;
  }
}

@include mq('TAB') {
  html {
    font-size: 60%;
  }
}

body {
  color: $c-black;
  font-size: 1.6em;
  font-family:-apple-system,BlinkMacSystemFont,"Helvetica Neue","Hiragino Kaku Gothic ProN","游ゴシック体",YuGothic,"游ゴシック Medium","Yu Gothic Medium","游ゴシック","Yu Gothic","メイリオ",Meiryo,sans-serif;
}

@include mq('SP') {
  body {
    font-size: 1.4em;
    overflow-x: hidden;
  }
}

a {
  display: block;
  text-decoration: none;
  color: $c-black;
  &:hover {
    text-decoration: none !important;
  }
}


@include mq('PC') {
  .pc {
    display: block;
  }
  .sp {
    display: none;
  }
}

@include mq('SP') {
  img {
    width: 100%;
  }
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
}
